<template>
  <div ref="nopay" class="nopay_cnt">
    <div class="cnt_box">
      <!-- 顶部标题 -->
      <headers ref="headers" :headname="headName"></headers>
      <!-- <p class="top_title">
        <span class="title_border"></span>
        <span class="title_text">未付款课程</span>
      </p> -->
      <!-- <div class="cnt_top">
        <span class="top_text">未付款课程</span>
        <span class="top_border"></span>
      </div> -->
      <!-- 中间box -->
      <div class="cnt_main">
        <div v-if="count>0" class="cnt_main_list">
          <template v-for="(lsson,index) in lists">
            <!-- 课程 -->
            <div :key="index" class="lesson_item">
              <!-- 缩略图 -->
              <div class="lesson_img" @click="goToLessonDetail(lsson)">
                <img :src="lsson.thumb" alt="">
              </div>
              <!-- 课程信息 -->
              <div class="lesson_info">
                <p class="lesson_name" @click="goToLessonDetail(lsson)">{{ lsson.lesson_name }}</p>
                <!-- 价格 -->
                <div class="price">
                  <span class="price_n">价格 </span>
                  <span class="price_r">￥{{ lsson.money }}</span>
                </div>
                <!-- 立即支付 -->
                <div class="jus-start">
                  <div class="pay_n" @click="payForLessonNow(lsson)">
                    立即支付
                  </div>
                  <div class="cancel_btn" @click="cancelFun(lsson)">
                    取消订单
                  </div>
                </div>

              </div>
            </div>
          </template>
          <el-pagination v-show="count>params.limit" class="page" background :total="count" layout="prev, pager, next" :page-size="params.limit" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
        <!-- 无数据 -->
        <noData v-if="count===0" ref="noData" :msg="msg" :img="img"></noData>
        <!-- <div v-else class="noDataBox">
          <img src="@/assets/image/no_data_lesson.png" alt="">
          <p>您还没有未付款课程哦~</p>
        </div> -->
      </div>
      <!-- 底部信息栏 -->
      <!-- <div class="cnt_bot_box">
        信息栏
      </div> -->
    </div>
  </div>
</template>

<script>
import { getWaitorOrders, getCancelOrder } from '@/api/center'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
import Cookies from 'js-cookie'
export default {
  components: { headers, noData },
  data() {
    return {
      combine_id:Cookies.get('combineId')||'',
      headName: '未付款课程',
      msg: '您还没有未付款课程哦~',
      img: require('@/assets/image/no_data_lesson.png'),
      params: {
        page: 1,
        url: '', // 课程详情链接
        limit: 10
      },
      lists: [],
      count: undefined,
      agency_id:''
    }
  },
  beforeCreate() {
  },
  created() {
    this.getWaitOrderLists()
  },
  mounted() {
    this.agency_id = Cookies.get('agency_id')
  },
  methods: {
    saveCookies(data){
      Cookies.set('categoryId', data.category_id)
      Cookies.set('categoryName', data.category_name)
      Cookies.set('sourceId', data.source_id)
      Cookies.set('combineId', data.combination_id)
      Cookies.set('combineName', data.combination_name)
    },
    // 未支付订单
    getWaitOrderLists() {
      const origin = window.location.origin
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        this.params.zkb_pro = 1
      }
      getWaitorOrders(this.params).then(res => {
        const list = res.result.list
        this.lists = list
        this.count = res.result.count
      }).catch(err => {
        console.log(err)
      })
    },
    // 立即支付
    payForLessonNow(item) {
      // 支付
      const origin = window.location.origin
      if(this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')){
        if(item.zkb_pro){
          this.saveCookies(item.zkb_pro)
        }
        const url = window.location.origin+`/pay?product_type=7&product_id=${item.zkb_pro.strategy_id}&zkbpro=1`
        window.open(url, '_blank')
      }else{
        window.open(item.pay_url, '_blank')
      }
    },
    // 去课程详情
    goToLessonDetail(item) {
       const origin = window.location.origin
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        const url = window.location.origin+`/kb_timetable?combine_id=${this.combine_id}&lesson_id=${item.lesson_id}&lesson_name=${item.lesson_name}#i=2`
        console.log(url, 'url')
        window.open(url, '_blank')
      } else {
        window.open(item.url, '_blank')
      }
    },
    // 分页
    handleCurrentChange(val) {
      //  当前点击页数
      this.params.page = val
      this.getWaitOrderLists()
    },
    // 取消订单
    cancelFun(e) {
      const orderID = e.order_id
      var params = {
        order_id: orderID
      }
      getCancelOrder(params).then(res => {
        if (res.errNo === 0) {
          this.getWaitOrderLists()
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cancel_btn {
  margin-left: 10px;
  background-color: #fff;
  display: inline-block;
  width: 90px;
  height: 30px;
  border-radius: 4px;
  border: 2px solid #ee2e2e;
  line-height: 26px;
  font-size: 14px;
  color: #ee2e2e;
  text-align: center;
  cursor: pointer;
}
.nopay_cnt {
  width: 965px;
  min-height: 900px;
  padding: 0 0 20px 0;
  position: relative;
  font-family: "PingFangSC-Regular";
}
.top_title {
  height: 55px;
  width: 100%;
  line-height: 20px;
  font-size: 18px;
  position: relative;
  text-align: left;
  .title_text {
    display: inline-block;
    margin-left: 9px;
    margin-top: 25px;
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #444444;
  }
}
.title_border {
  float: left;
  height: 20px;
  margin-top: 30px;
  margin-left: 20px;
  width: 4px;
  background: #f04e4f;
}
// .cnt_top {
//   position: relative;
//   height: 55px;
//   width: 100%;
//   padding-top: 30px;
//   padding-left: 23px;
//   line-height: 20px;
//   font-size: 18px;
//   text-align: left;
// }
// .top_border {
//   position: absolute;
//   left: 10px;
//   width: 4px;
//   height: 20px;
//   background: #f04e4f;
// }
.cnt_main_list {
  position: relative;
  margin-left: 30px;
  // padding: 6px 10px 0;
  // min-height: 500px;
}
.lesson_item {
  height: 180px;
  padding: 30px 0;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  display: flex;
}
.lesson_img {
  width: 157px;
  height: 120px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.lesson_info {
  background-color: #fff;
  float: left;
  display: flex;
  height: 120px;
  width: 600px;
  margin-left: 20px;
  font-size: 16px;
  color: #444444;
  flex-direction: column;
  justify-content: space-between;
  .lesson_name {
    cursor: pointer;
  }
  .price {
    line-height: 30px;
    .price_n {
      font-size: 12px;
      color: #333333;
    }
    .price_r {
      font-size: 12px;
      color: #ee2e2e;
    }
  }
  .pay_n {
    width: 90px;
    height: 30px;
    background-color: #ee2e2e;
    border-radius: 4px;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
.page {
  padding: 20px 0;
  margin: 0 auto;
}
.noDataBox {
  position: relative;
  width: 100%;
  min-height: 500px;
  img {
    margin-top: 140px;
    width: 140px;
    height: 140px;
  }
  p {
    margin-top: 35px;
    text-align: center;
    line-height: 20px;
  }
}
.cnt_bot_box {
  margin-top: 60px;
  padding: 10px;
  height: 20px;
  background-color: #fff;
}
.jus-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
